import React, { useState } from 'react';
import './App.css';

const Slider = ({ minSentiment, onMinSentimentChange }) => {
  const [firstChange, setFirstChange] = useState(false);

  // Function to determine the emoji based on minSentiment value
  const getEmoji = () => {
    if (minSentiment < 0) {
      return "😞-😊"; // Sad emoji
    } else if (minSentiment >= 0 && minSentiment <= 0.5) {
      return "😐-😊"; // Neutral emoji
    } else {
      return "😊"; // Happy emoji
    }
  };

  // Function to handle slider value change
  const handleChange = (event) => {
    const newValue = parseInt(event.target.value); // Convert to integer
    onMinSentimentChange(newValue); // Call the function from App.js to update minSentiment
    if (!firstChange) {
      setFirstChange(true);
    }
  };

  // Function to handle applying changes
  const handleApply = () => {
    window.location.reload(); // Refresh the page
  };

  return (
    <div className="slider-container">
      <div><p className='small-font'>Sentimentul știrilor ales: {getEmoji()}</p></div>
      <input
        type="range"
        min="-1"
        max="1"
        step="1"
        value={minSentiment}
        onChange={handleChange}
        className="slider"
        style={{ background: `linear-gradient(to right, red,yellow, green)` }}
      />
      <button className={`apply-button heavy-font red-button ${firstChange ? 'visible' : 'hidden'}`} onClick={handleApply}>Aplică</button>
    </div>
  );
};

export default Slider;
