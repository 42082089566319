import React, { useEffect, useState, useRef } from 'react';
import './App.css'; // Assuming you have created a corresponding CSS file
import $ from 'jquery'; // Import jQuery
import { Gauge } from 'react-circular-gauge'
import chroma from 'chroma-js'
import AdComponent from './AdComponent.js';
import Slider from './Slider.js';

function NewsPage({ category }) {
  const [packets, setPackets] = useState([]);
  const [isFetching, setFetching] = useState(false);

  const containerRef = useRef('main_scroll');
  const packetsRef = useRef(packets);

  const [minSentiment, setMinSentiment] = useState(() => {  
    const savedMinSentiment = localStorage.getItem('minSentiment');  
    return savedMinSentiment !== null ? Number(savedMinSentiment) : 0;  
  });  
  
  // Function to handle changes in minSentiment
  const handleMinSentimentChange = (value) => {
    setMinSentiment(value);
    localStorage.setItem('minSentiment', JSON.stringify(value)); // Save the value to local storage
  };


  var last_call_500 = false

  //get connection url from os env
  const url = process.env.REACT_APP_API_URL;

 
  const getArticles = (category) => {
    console.log(minSentiment)
    // Simulate fetching articles using jQuery AJAX
    $.ajax({
      type: "GET",
      url: url,
      data: { category: category, index: packetsRef.current.length, sentiment: minSentiment},
      success: function (response) {
        // if status 500
        if (response.status == 500) {
          last_call_500 = true;
          return;
        }

        console.log(response)
        //dont set but add to the packets
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
        // append to our array
        setPackets(packets => [...packets, ...response]);

      },
      error: function (xhr, status, error) {
        last_call_500 = true;
        console.log("Here")
        console.error("Error fetching articles:", error);
      }
    });
  };



  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = (event) => {
    console.log('toggleMenu called, current value of isOpen:', isOpen);  
    setIsOpen(!isOpen);
    event.stopPropagation()
  }

  useEffect(() => {
    const scriptUrls = [
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBVWaKrjvy3MaE7SQ74_uJiULgl1JY0H2s&sensor=false',
      '/jquery.min.js',
      '/jquery-migrate-3.0.1.min.js',
      '/popper.min.js',
      '/bootstrap.min.js',
      '/jquery.easing.1.3.js',
      '/jquery.waypoints.min.js',
      '/jquery.stellar.min.js',
      '/owl.carousel.min.js',
      '/jquery.magnific-popup.min.js',
      '/jquery.animateNumber.min.js',
      '/scrollax.min.js',
      '/aos.js',
      '/google-map.js',
      '/main.js',
      '/cookiealert.js'
    ];

    scriptUrls.forEach(url => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.defer = true;
      script.type = "text/jsx";
      document.body.appendChild(script);
    });

    getArticles(category);


    return () => {
      scriptUrls.forEach(url => {
        const script = document.querySelector(`script[src="${url}"]`);
        if (script) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);

  useEffect(() => {
    packetsRef.current = packets;
  }, [packets]);





  const onScroll = () => {
    if (!isFetching) {
      const totalPageHeight = document.body.scrollHeight;
      const scrollPoint = window.scrollY + window.innerHeight;

      if (scrollPoint >= totalPageHeight - 100 && !isFetching) {
        window.removeEventListener('scroll', onScroll);
        AddMoreArticles();
      }
    }

  };


  useEffect(() => {

    setTimeout(() => {
      if (isFetching) {
        setFetching(false);

      }
    }, 3000);

    if (!isFetching) {
      window.addEventListener('scroll', onScroll);
    }
    return () => {
    };
  }, [isFetching]);


  const AddMoreArticles = () => {
    setFetching(true);
    getArticles(category);
  }


  const getDisplayImage = (packet) => {
    //make sure the firs image endswith png or jpg or bmp gif etc but iterate through the articles
    for (let i = 0; i < packet.articles.length; i++) {
      if (packet.articles[i].pictureURL && (packet.articles[i].pictureURL.includes("png") || packet.articles[i].pictureURL.includes("jpg") || packet.articles[i].pictureURL.includes("bmp") || packet.articles[i].pictureURL.includes("gif") || packet.articles[i].pictureURL.includes("jpeg"))) {
        return packet.articles[i].pictureURL;
      }
    }
  };


  const getDisplayLink = (packet) => {
    //need to find the corresponding article above so we apply the same logic
    for (let i = 0; i < packet.articles.length; i++) {
      if (packet.articles[i].pictureURL && (packet.articles[i].pictureURL.includes("png") || packet.articles[i].pictureURL.includes("jpg") || packet.articles[i].pictureURL.includes("bmp") || packet.articles[i].pictureURL.includes("gif")  || packet.articles[i].pictureURL.includes("jpeg"))) {
        return packet.articles[i].link;
      }
    }
  };


  const get_display_date = (date) => {
    //date is gmt, we need to convert it to local time

    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));

    const now = Date.now();
    const diff = now - localDate;
    let amount = Math.round(diff / 60000);
    let units = "minute";

    if (amount >= 60 && amount <= 120) {
      amount = 'o';
      units = "oră";
    } else if (amount >= 120 && amount <= 1440) {
      amount = Math.round(amount / 60);
      units = "ore";
    } else if (amount >= 1440 && amount <= 2880) {
      amount = "o";
      units = "zi";
    } else if (amount > 2880) {
      amount = Math.round(amount / 1440);
      units = "zile";
    }

    return "Acum " + amount + " " + units;
  };

  return (
    <div id="colorlib-page">
      <div>
        <div style={{ padding: '1em' }} className={`js-colorlib-nav-toggle  toggle-bar topbar colorlib-nav-toggle ${isOpen ? 'active' : ''}`}><p><i onClick={toggleMenu}>
        </i></p>
          <div style={{ textAlign: 'right' }}>
            <div className="logo-container">
              <a href="/" style={{ display: 'block', textAlign: 'center' }}>
                <img className="d-block" src={"./logo_cropped.svg"} alt="Logo" style={{ width: '13em', margin: '0em auto', }} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <aside id="colorlib-aside" role="complementary" className={`colorlib-aside ${isOpen ? 'sidebar-open' : ''}`}>
        <a href="/" className='a-logo' style={{ display: 'block', textAlign: 'center' }}>
          <img className="d-block" src={"./logo_full.svg"} alt="Logo" style={{ width: '80%', margin: '0 auto' }} />
        </a>
        <div className="slider-container">
        <Slider minSentiment={minSentiment} onMinSentimentChange={handleMinSentimentChange} />
          
        </div>
        <nav id="colorlib-main-menu" role="navigation">
          <ul className='colorlib-main-menu'>
            <li className={category === "ACTUALITATE" ? "colorlib-active" : ""}><a href="/">Actualitate</a></li>
            <li className={category === "POLITICA" ? "colorlib-active" : ""}><a href="/politica">Politică</a></li>
            <li className={category === "JUSTITIE" ? "colorlib-active" : ""}><a href="/justitie">Justiție</a></li>
            <li className={category === "SOCIAL" ? "colorlib-active" : ""}><a href="/social">Social</a></li>
            <li className={category === "EDUCATIE" ? "colorlib-active" : ""}><a href="/educatie">Educație</a></li>
            <li className={category === "SANATATE" ? "colorlib-active" : ""}><a href="/sanatate">Sănătate</a></li>
            <li className={category === "CULTURA" ? "colorlib-active" : ""}><a href="/cultura">Cultură</a></li>
            <li className={category === "SPORT" ? "colorlib-active" : ""}><a href="/sport">Sport</a></li>
            <li className={category === "EXTERNE" ? "colorlib-active" : ""}><a href="/externe">Externe</a></li>
            <li className={category === "STIINTA_SI_TEHNOLOGIE" ? "colorlib-active" : ""}><a href="/stiinta">Știință</a></li>
            <li className={category === "termeni_si_conditii" ? "colorlib-active" : ""}><a href="/termeni_si_conditii">Termeni și condiții</a></li>
            <li className={category === "contact" ? "colorlib-active" : ""}><a href="/contact">Contact</a></li>
          </ul>
        </nav>
      </aside>
      <div id="colorlib-main" ref={containerRef} >
        <section className="ftco-section ftco-animate ftco-no-pt ftco-no-pb">
          <div className="container-fluid">
            <div className="py-4">
              <div id="main_scroll" className="">

                {packets.map((packet, index) => (


                    <div key={index} className="row pt-md-2 px-2">
                    
                    {(index % 5 === 0 && index !== 0) && document.querySelector('.adsbygoogle[data-ad-status="filled"]') && (
                    <AdComponent />
                    )}

                      <div className="col-md-12 fade-out my-2">
                        <div className='background sentiment-card'>

                          <b className='right small'>
                            Sentiment:
                          </b>
                          <span className='small'><Gauge
                            value={((packet.sentiment + 1) / 2) * 100}
                            style={{ maxHeight: "30px", maxWidth: "30px" }}
                            startAngle={70}
                            trackColor={'#616161'}
                            endAngle={290}
                            arcWidth={0.3}
                            fontSize={'70%'}
                            minValue={0}
                            maxValue={100}
                            renderValue=""
                            arcColor={({ normValue }) => chroma.scale(['red', 'yellow', 'green',])(normValue).css()}
                          />
                          </span>
                          <b className='small center' style={{ marginTop: "-10px", fontWeight: "700"}}>
                            {((packet.sentiment + 1) / 2) * 100 <= 30 ? 'negativ' : ((packet.sentiment + 1) / 2) * 100 >= 75 ? 'pozitiv ' : 'neutru  '}
                          </b>
                        </div>
                        <div className="blog-entry justify-content-center d-md-flex d-block py-2">

                          <a
                            target=""
                            rel="noopener noreferrer"
                            href={getDisplayLink(packet)}
                          >
                            <ptop className="small boundRight">Sursă foto: {packet.articles[0].agency}</ptop>
                            <ptop className="small boundRightLower"><i className="icon-alarm"></i>
                              {get_display_date(new Date(packet.gmtDate))}</ptop>
                            <img
                              src={getDisplayImage(packet)}
                              alt="Article Image"
                              className="make-small shadow"
                            />
                          </a>

                          <div className="text text-2 pl-md-4">
                            <div className="row py-2">
                              {packet.articles.map((article, articleIndex) => (

                                <a
                                  key={articleIndex}
                                  href={article.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="mb-2 centered-link"
                                >

                                  <img
                                    className="rounded-circle shadow mx-1 zoom d-block"
                                    src={`${article.agency}.png`}
                                    width="35"
                                    alt="Source Logo"
                                  />
                                </a>
                              ))}
                            </div>
                            <div className="d-flex flex-column">
                              <a
                                href={packet.articles[0].link}
                                style={{ textDecoration: "none" }}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="centered-link"

                              >
                                <div>
                                  <h6 style={{ paddingTop: "0em" }} className="text-left font-weight-bold">{packet.title}</h6>
                                </div>
                              </a>

                              <div className="summary">
                                <h6 className='font-weight-bold-summary centered' >Rezumat AI<img src="wand.png" alt="wand" width="20" height="20" /></h6>

                                <h6 className='font-small align-text-left'>{packet.summary}</h6>
                              </div>

                              <div>
                                <p className="meta center">
                                  <span>

                                  </span>
                                </p>

                              </div>

                            </div>

                          </div>

                        </div>

                      </div>


                    </div>


                )
                )}


              </div>

            </div>


          </div>


        </section>
        <div id="ftco-loader" className={isFetching ? "show" : "hide"} style={{ position: 'relative' }}>
          <svg className="circular" width="48px" height="48px">
            <circle className="path-bg" cx="24" cy="24" r="22" fill="none" strokeWidth="4" stroke="#eeeeee" />
            <circle className="path" cx="24" cy="24" r="22" fill="none" strokeWidth="4" strokeMiterlimit="10" stroke="#b50000" />
          </svg>
        </div>
      </div>
      <div id="ftco-loader" className={packets.length > 0 ? "hide fullscreen" : "show fullscreen"}>
        <svg className="circular" width="48px" height="48px">
          <circle className="path-bg" cx="24" cy="24" r="22" fill="none" strokeWidth="4" stroke="#eeeeee" />
          <circle className="path" cx="24" cy="24" r="22" fill="none" strokeWidth="4" strokeMiterlimit="10" stroke="#b50000" />
        </svg>

      </div>
    </div>
  );
}

export default NewsPage;
