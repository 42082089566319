import React, { useEffect } from 'react';

const AdComponent = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.crossOrigin = "anonymous";
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    document.body.appendChild(script);

    const timeoutId = setTimeout(() => {
      if (window.adsbygoogle && window.adsbygoogle.loaded) {
        console.log(1);
        window.adsbygoogle.push({});
      }
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
      document.body.removeChild(script);
    };
  }, []);


  return (
    <ins className="adsbygoogle"
         style={{display: 'block', width: '100%', height: '100%'}}
         data-ad-format="fluid"
        //  data-ad-layout-key="-6j+eg+1g-3c+2w"
         data-ad-client="ca-pub-5376075777526670"
         data-ad-slot="3249897482"
         >
    </ins>
  );
};

export default AdComponent;
