import React, { useEffect, useState } from 'react';
import './App.css'; // Assuming you have created a corresponding CSS file

function Contact({category}) {


    const [isOpen, setIsOpen] = useState(false);
  
    const toggleMenu = () => {
      setIsOpen(!isOpen);
    }

  useEffect(() => {
    const scriptUrls = [
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBVWaKrjvy3MaE7SQ74_uJiULgl1JY0H2s&sensor=false',
      '/jquery.min.js',
      '/jquery-migrate-3.0.1.min.js',
      '/popper.min.js',
      '/bootstrap.min.js',
      '/jquery.easing.1.3.js',
      '/jquery.waypoints.min.js',
      '/jquery.stellar.min.js',
      '/owl.carousel.min.js',
      '/jquery.magnific-popup.min.js',
      '/jquery.animateNumber.min.js',
      '/scrollax.min.js',
      '/aos.js',
      '/google-map.js',
      '/main.js',
      '/cookiealert.js'
    ];

    scriptUrls.forEach(url => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.defer = true;
      script.type = "text/jsx";
      document.body.appendChild(script);
    });


    
    

    // Set the packets state with the mock data

    return () => {
      scriptUrls.forEach(url => {
        const script = document.querySelector(`script[src="${url}"]`);
        if (script) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);




  return (
    <div id="colorlib-page">
      <div>
        <div style={{ padding: '1em' }} className={`js-colorlib-nav-toggle  toggle-bar topbar colorlib-nav-toggle ${isOpen ? 'active' : ''}`}><p><i  onClick={toggleMenu}>
        </i></p>
        <div className="" style={{ textAlign: 'right' }}>
        <div class="logo-container">
        <a href="/" style={{ display: 'block', textAlign: 'center' }}>
          <img className="d-block" src={"./logo_full.svg"} alt="Logo" style={{ width: '80%', margin: '0 auto' }} />
        </a>
        </div>
      </div>
    </div>
  </div>
      <aside id="colorlib-aside" role="complementary" className={`colorlib-aside ${isOpen ? 'sidebar-open' : ''}`}>
        <a href="/" className='a-logo' style={{ display: 'block', textAlign: 'center' }}>
        <img className="d-block" src={"./logo_full.svg"} alt="Logo" style={{ width: '80%', margin: '0 auto' }} />
          </a>
        <nav id="colorlib-main-menu" role="navigation">
          <ul className='colorlib-main-menu'>
            <li className={category === "actualitate" ? "colorlib-active" : ""}><a href="/">Actualitate</a></li>
            <li className={category === "politica" ? "colorlib-active" : ""}><a href="/politica">Politică</a></li>
            <li className={category === "justitie" ? "colorlib-active" : ""}><a href="/justitie">Justiție</a></li>
            <li className={category === "social" ? "colorlib-active" : ""}><a href="/social">Social</a></li>
            <li className={category === "educatie" ? "colorlib-active" : ""}><a href="/educatie">Educație</a></li>
            <li className={category === "sanatate" ? "colorlib-active" : ""}><a href="/sanatate">Sănătate</a></li>
            <li className={category === "cultura" ? "colorlib-active" : ""}><a href="/cultura">Cultură</a></li>
            <li className={category === "sport" ? "colorlib-active" : ""}><a href="/sport">Sport</a></li>
            <li className={category === "externe" ? "colorlib-active" : ""}><a href="/externe">Externe</a></li>
            <li className={category === "stiinta" ? "colorlib-active" : ""}><a href="/stiinta">Știință</a></li>
            <li className={category === "termeni_si_conditii" ? "colorlib-active" : ""}><a href="/termeni_si_conditii">Termeni și condiții</a></li>
            <li className={category === "Contact" ? "colorlib-active" : ""}><a href="/contact">Contact</a></li>
          </ul>
        </nav>
      </aside>
      <div id="colorlib-main" style={{ paddingTop: '40px' }}>
        
        <section className="ftco-section ftco-animate ftco-no-pt ftco-no-pb">
          <div className="colorlib-main-menu">
              <div className="py-4">
                <div className="row pt-md-2">
                  

                  <div class="mx-4 my-4">
                                        <h6 class="text-left mx-auto"><h3>Contact:</h3>

                          <p>contact.outline.news@gmail.com </p>


                          <h3>Social media:</h3>
                          <p> 


                          <a class="fa fa-facebook-square" href="https://ww.facebook.com/outlinenews">Facebook</a><p></p>
                          <a class="fa fa-instagram" href="https://www.instagram.com/outline_news">Instagram</a>
                          </p></h6>
                          </div>

              
              </div>
              
              </div>
              
          </div>


        </section>
      </div>
      <div id="ftco-loader" className={1 > 0 ? "hide fullscreen" : "show fullscreen"}>
      <svg className="circular" width="48px" height="48px">
        <circle className="path-bg" cx="24" cy="24" r="22" fill="none" strokeWidth="4" stroke="#eeeeee"/>
        <circle className="path" cx="24" cy="24" r="22" fill="none" strokeWidth="4" strokeMiterlimit="10" stroke="#b50000"/>
      </svg>
    </div>    
  </div>
  );
}

export default Contact;
