import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NewsPage from './NewsPage';
import TermsAndConditions from './TermsAndConditions';
import Contact from './Contact';

function App() {

    return (
      <div>
        <Routes>
          <Route path="/" element={<NewsPage  category="ACTUALITATE"/>} />
          <Route path="/politica" element={<NewsPage category="POLITICA" />} /> 
            <Route path="/justitie" element={<NewsPage category="JUSTITIE" />} />
            <Route path="/social" element={<NewsPage category="SOCIAL" />} />
            <Route path="/educatie" element={<NewsPage category="EDUCATIE" />} />
            <Route path="/sanatate" element={<NewsPage category="SANATATE" />} />
            <Route path="/cultura" element={<NewsPage category="CULTURA" />} />
            <Route path="/sport" element={<NewsPage category="SPORT" />} />
            <Route path="/externe" element={<NewsPage category="EXTERNE" />} />
            <Route path="/stiinta" element={<NewsPage category="STIINTA_SI_TEHNOLOGIE" />} />
            <Route path="/termeni_si_conditii" element={<TermsAndConditions category="termeni_si_conditii"/>} />
            <Route path="/contact" element={<Contact category="contact"/>} />
        </Routes>
      </div>
    );
  }
  
  export default App;