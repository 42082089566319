import React, { useEffect, useState } from 'react';
import './App.css'; // Assuming you have created a corresponding CSS file

function TermsAndConditions({ category }) {


    const [isOpen, setIsOpen] = useState(false);
  
    const toggleMenu = () => {
      setIsOpen(!isOpen);
    }

  useEffect(() => {
    const scriptUrls = [
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBVWaKrjvy3MaE7SQ74_uJiULgl1JY0H2s&sensor=false',
      '/jquery.min.js',
      '/jquery-migrate-3.0.1.min.js',
      '/popper.min.js',
      '/bootstrap.min.js',
      '/jquery.easing.1.3.js',
      '/jquery.waypoints.min.js',
      '/jquery.stellar.min.js',
      '/owl.carousel.min.js',
      '/jquery.magnific-popup.min.js',
      '/jquery.animateNumber.min.js',
      '/scrollax.min.js',
      '/aos.js',
      '/google-map.js',
      '/main.js',
      '/cookiealert.js'
    ];

    scriptUrls.forEach(url => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.defer = true;
      script.type = "text/jsx";
      document.body.appendChild(script);
    });


    
    

    // Set the packets state with the mock data

    return () => {
      scriptUrls.forEach(url => {
        const script = document.querySelector(`script[src="${url}"]`);
        if (script) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);




  return (
    <div id="colorlib-page">
      <div>
        <div style={{ padding: '1em' }} className={`js-colorlib-nav-toggle  toggle-bar topbar colorlib-nav-toggle ${isOpen ? 'active' : ''}`}><p><i  onClick={toggleMenu}>
        </i></p>
        <div className="" style={{ textAlign: 'right' }}>
        <div class="logo-container">
        <a href="/" style={{ display: 'block', textAlign: 'center' }}>
        <img className="d-block" src={"./logo_cropped.svg"} alt="Logo" style={{ width: '13em', margin: '0em auto', }} />
        </a>
        </div>
      </div>
    </div>
  </div>
      <aside id="colorlib-aside" role="complementary" className={`colorlib-aside ${isOpen ? 'sidebar-open' : ''}`}>
        <a href="/" className='a-logo' style={{ display: 'block', textAlign: 'center' }}>
        <img className="d-block" src={"./logo_full.svg"} alt="Logo" style={{ width: '80%', margin: '0 auto' }} />
          </a>
        <nav id="colorlib-main-menu" role="navigation">
          <ul className='colorlib-main-menu'>
            <li className={category === "actualitate" ? "colorlib-active" : ""}><a href="/">Actualitate</a></li>
            <li className={category === "politica" ? "colorlib-active" : ""}><a href="/politica">Politică</a></li>
            <li className={category === "justitie" ? "colorlib-active" : ""}><a href="/justitie">Justiție</a></li>
            <li className={category === "social" ? "colorlib-active" : ""}><a href="/social">Social</a></li>
            <li className={category === "educatie" ? "colorlib-active" : ""}><a href="/educatie">Educație</a></li>
            <li className={category === "sanatate" ? "colorlib-active" : ""}><a href="/sanatate">Sănătate</a></li>
            <li className={category === "cultura" ? "colorlib-active" : ""}><a href="/cultura">Cultură</a></li>
            <li className={category === "sport" ? "colorlib-active" : ""}><a href="/sport">Sport</a></li>
            <li className={category === "externe" ? "colorlib-active" : ""}><a href="/externe">Externe</a></li>
            <li className={category === "stiinta" ? "colorlib-active" : ""}><a href="/stiinta">Știință</a></li>
            <li className={category === "termeni_si_conditii" ? "colorlib-active" : ""}><a href="/termeni_si_conditii">Termeni și condiții</a></li>
            <li className={category === "Contact" ? "colorlib-active" : ""}><a href="/contact">Contact</a></li>
          </ul>
        </nav>
      </aside>
      <div id="colorlib-main" style={{ paddingTop: '40px' }}>
        <section className="ftco-section ftco-animate ftco-no-pt ftco-no-pb">
          <div className="container-fluid">
              <div className="py-2">
                <div id="main_scroll" className="row pt-md-2">
                  

                <div class="row pt-md-4">
			    			<div class="col-md-12 my-2">
									<div class="blog-entry d-md-flex">
				              <h6 class="text-left mx-auto">
                      <section>
        <h3 className='colorlib-aside'>Definirea termenilor</h3>
        <p>
            <strong>Ştire:</strong> Un articol scris de o publicație.
        </p>
        <p>
            <strong>Pachet (pachet de știri):</strong> O grupare de știri de la mai multe publicații care vizează același eveniment.
        </p>
    </section>

    <section>
        <h3>1. Integritatea conținutului</h3>
        <p>
            Acestă aplicație și componentele sale sunt oferite doar pentru scopuri informative; aplicația nu va fi responsabilă sau răspunzătoare pentru acuratețea, utilitatea sau valabilitatea oricărei informații transmise sau făcute disponibile pe acest site și nu va fi responsabilă sau răspunzătoare pentru orice eroare sau omisiune în acea informație.
        </p>
    </section>

    <section>
        <h3>2. Proprietatea conținutului</h3>
        <p>
            Aplicația și tot ce cuprinde această (interfață, logo, design) fără limitare sunt proprietatea Outline Software Solutions SRL, cu excepția articolelor și conținutului acestora, deschise în browser. Este strict interzisă utilizarea oricărui conținut a cărei proprietate este în dreptul Outline Software Solutions SRL fără acordul în prealabil. Pentru orice informație suplimentară puteți să ne contactați în rubrica "Contact".
        </p>
    </section>

    <section>
        <h3>3. Prin utilizarea aplicației</h3>
        <p>
            Prin utilizarea aplicației vă asumați că ați înțeles următoarele condiții:
        </p>
        <ul>
            <p>Aplicația retransmite informații furnizate de site-uri, grupate folosind tehnici proprii. Aceasta respectă regulile de citare, afișează sursa și link-ul către întregul articol. Proprietatea conținutului site-urilor este în totalitate al publicațiilor care l-au produs.</p>
            <p>Un utilizator care a acționat sau a luat o decizie în urma unei informații transmise de aplicație nu poate solicita compensații pentru eventualele daune rezultate din aceasta.</p>
        </ul>
    </section>

    <section>
        <h3>4. Conținutul unui pachet de știri</h3>
        <p>
            Conținutul unui pachet de știri este determinat prin tehnici proprii care depind de felul în care este scris un articol, sursele de la care preluam informațiile. Ordinea știrilor într-un pachet este aleatoare.
        </p>
    </section>

    <section>
        <h3>5. Monetizarea</h3>
        <p>
            Aplicația folosește servicii de monetizare precum Google Adsense pentru a afișa reclame pe paginile aplicației.
        </p>
    </section>

    <section>
        <h3>6. Exonerarea de răspundere</h3>
        <p>
            Outline Software Solutions sau orice altă parte implicată în conceperea, producerea sau oferirea aplicației nu sunt răspunzătoare pentru daune directe sau indirecte care ar rezulta din sau în legătură cu utilizarea acestei aplicații sau a conținutului său.
        </p>
    </section>

    <section>
        <h3>7. Link-uri la site-urile unei agenții</h3>
        <p>
            Aplicația conține link-uri către alte site-uri. Aceasta nu își asumă responsabilitatea pentru conținutul, condițiile de confidențialitate sau securitatea și funcționalitatea acestor site-uri.
        </p>
    </section>
                      </h6>
                                            </div>

                </div>
              </div>
              </div>
          </div>
          </div>


        </section>
      </div>
      <div id="ftco-loader" className={1 > 0 ? "hide fullscreen" : "show fullscreen"}>
      <svg className="circular" width="48px" height="48px">
        <circle className="path-bg" cx="24" cy="24" r="22" fill="none" strokeWidth="4" stroke="#eeeeee"/>
        <circle className="path" cx="24" cy="24" r="22" fill="none" strokeWidth="4" strokeMiterlimit="10" stroke="#b50000"/>
      </svg>
    </div>    
  </div>
  );
}

export default TermsAndConditions;
